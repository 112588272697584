import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/Humanity/2024/MootCourt/1.jpg';
// import p2 from 'assests/Photos/Clubs/Humanity/2024/MootCourt/2.jpg';
// import p3 from 'assests/Photos/Clubs/Humanity/2024/MootCourt/3.jpg';
// import p4 from 'assests/Photos/Clubs/Humanity/2024/MootCourt/4.jpg';
// import p5 from 'assests/Photos/Clubs/Humanity/2024/MootCourt/5.jpg';
// import p6 from 'assests/Photos/Clubs/Humanity/2024/MootCourt/6.jpg';

// import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';


import { Typography } from '@mui/material';
import Container from 'components/Container';
// import Sidebar2023 from './Sidebar2023';
import Sidebar2023 from 'views/Humanity2023/Sidebar2023';
import Sidebar2022 from 'views/HumanityClub/components/Sidebar2022';
import Sidebar2024 from '../Humanity2023/Sidebar2024';


const MootCourt2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

 
  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/association/Humanity/MootCourt/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/association/Humanity/MootCourt/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/association/Humanity/MootCourt/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/association/Humanity/MootCourt/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/association/Humanity/MootCourt/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/association/Humanity/MootCourt/6.webp`;
 


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h5' align='center' >
      MOOT COURT 
                                </Typography>
                                <br></br>
                                {/* <Typography variant='h7' align='justify' >
                                Class 4: Diversity Montage
                                Class 5: Continental Montage 

                                </Typography> */}
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 10 & 12  &  Date: 05 July 2024
        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “Knowing law is understanding the difference between knowing what is right to do, and what you have to right to do”-Potter Stewart

<br/>
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Humanities Association of National Public School, Yeshwanthpur organized ‘MOOT COURT’ on 05 July 2024 for the
        students of classes 10 to 12. A moot court is an event simulating real courtroom environment, allowing students to
        associate themselves as a lawyer, by arguing about either hypothetical or real-world cases. It involves identifying legal
        issues out of a complex factual scenario, followed by doing exhaustive research on those issues to advance arguments
        with authorities and finally the gruelling orals before a bench of eminent judges. In a nutshell, it is everything a lawyer
        is expected to do once he starts practising law.   
        <br></br>
        The students exhibited strong legal reasoning and argumentative skills in an exhilarating courtroom simulation where
        teams presented and defended a case. They had to demonstrate an in-depth understanding of legal principles,
        articulate their arguments convincingly and respond adeptly to counter arguments. It provided a valuable learning
        experience that bridged theoretical knowledge with practical application, enhancing the participant’s understanding of
        the legal system and their analytical abilities.
        <br></br>
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “Winning a case isn’t just about knowing the law- it is about mastering the art of persuasion and making your voice

       impossible to ignore.”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2024 />
             </Box>

             <Box marginBottom={4}>
               <Sidebar2022 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default MootCourt2024;